<template>
  <!--头部-->
  <div class="header-box">
    <div class="header-content">
      <h2><span>调试助手</span></h2>
      <div>
        <span>{{ userInfo }}</span>
        <span><el-button type="text" @click="logout">退出</el-button></span>
      </div>
    </div>
  </div>
</template>

<script>
import { logout } from "../../request/index/login";

export default {
  props: {
    userInfo: "",
  },
  name: "Header",
  methods: {
    async logout() {
      const confirmResult = await this.$confirm(`确认退出？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);

      if (confirmResult !== "confirm")
        return this.$message.info("您取消了退出");

      const { data: res } = await logout({
        userName: this.userInfo,
      });
      if (res.code == 401) {
        window.localStorage.clear();
        // 使用编程式导航跳转到登录页面
        await this.$router.push("/login");
        return this.$message.error(res.message);
      }
      if (res.code !== 200) return this.$message.error(res.message);

      // 清除本地的 token
      window.localStorage.clear();
      // 使用编程式导航跳转到登录页面
      await this.$router.push("/login");
    },
  },
};
</script>

<style scoped lang="scss">
.header-box {
  width: 100%;
  height: 100%;
  max-height: 64px;
  // height: 64px;
  // line-height: 64px;
  background: #56327a;
  box-shadow: 0 3px 8px 0 rgba(1, 0, 1, 0.2);

  .header-content {
    position: relative;
    margin: 0 20px;

    h2 {
      width: 700px;
      height: 100%;
      // height: 64px;
      font-weight: 400;
      color: #ffffff;
      float: left;
      margin: 1.1% 0 1.1% 20px;

      span:first-child {
        font-size: 24px;
        font-family: "Times New Roman";
      }

      span:nth-child(2) {
        opacity: 0.7;
        padding: 0 5px;
      }

      span:last-child {
        font-size: 21px;
        font-family: "SourceHanSansCN-Regular";
      }
    }

    > div {
      height: 28px;
      line-height: 28px;
      float: right;
      margin: 1.1% 40px 1.1% 0;

      span {
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        font-family: Arial;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);

        .el-button {
          padding: 0;
          margin-left: 20px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.6);

          &:hover {
            color: rgba(255, 255, 255, 1);
          }
        }
      }
    }
  }
}
</style>
