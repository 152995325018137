import { request } from "./network";

// 获取呼出侧边栏--图片
export function getDrawerHandle(params) {
    return request({
        method: "get",
        url: "/student/images",
        params,
    });
}

// 通过老师ID与科目UUID获取学生列表
export function getStudentLists(params) {
    return request({
        method: "get",
        url: "/group/select/groupInfo",
        params,
    });
}
//前台获取数据
export function getStudentsAlic(subjectId, examUuid) {
    // let examUuid = localStorage.getItem('index-examId');
    let teacherId = localStorage.getItem('index-teacherId');
    return request({
        method: "post",
        url: "/getStudentsAlic/"+examUuid+'/'+subjectId+'/'+teacherId+'?startPage=1&offset=1000000',
        headers: {
            Authorization: "Bearer " + window.localStorage.getItem('index-token')
          }
    });
}
//后台获取数据
export function getStudentsAlic2(subjectId, examUuid) {
    // let examUuid = localStorage.getItem('index-examId');
    let teacherId = localStorage.getItem('index-teacherId');
    return request({
        method: "post",
        url: "/getStudentsAlic/"+examUuid+'/'+subjectId+'?startPage=1&offset=1000000',
        headers: {
            Authorization: window.localStorage.getItem('admin-token')
          }
    });
}
export function listChatRecord(subjectId,page = 1,pageSize = 10) {
    let teacherId = localStorage.getItem('index-teacherId');
    return request({
        method: "get",
        url: "/api/record/listChatRecord/"+subjectId+'/'+teacherId+'?start='+page+'&limit='+pageSize,
    });
}

export function signMessageReadable(id) {
    return request({
        method: "post",
        url: "/api/record/signMessageReadable/"+id,
    });
}


//指定终端用户从频道踢出
export function removeUser(subjectUuId,examUuid,channelId,user_id) {
    return request({
        method: "get",
        url: "removeTerminals/"+examUuid+'/'+subjectUuId+'/'+channelId+"?terminalIds="+user_id,
    });
}

export function refreshToken(){
    return request({
        method: "get",
        url: "login/teacher/token_refresh",
        Authorization: true
    });
}
