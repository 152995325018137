//监控人员相关
import 'aliyun-webrtc-sdk';
import {
    formatTime
} from "common/utils";
import {
    getStudentsAlic,
    listChatRecord,
    signMessageReadable,

    refreshToken
} from "r/index/examstudentinfolist";

import configApp from "config/index";
import SockJS from "sockjs-client";
import Stomp from "stompjs";
import localForage from "localforage";
// 数据监控
import {
    updateTestStatus,
    removeUser,
    monitorNumListener,
    recordChannel
} from "r/index/videoList";


import {
    isString
} from "element-ui/src/utils/types";


const selectOptions = ['所有考生', '消息'];
const studentStartInfo = {
    channelId: '',
    subjectUuid: '',
    groupId: '',
    index: '',
    key: '',
    name: '',
    subjectId: '',
    zkzNum: '',
    '0': {
        is_join: 0,
        is_video: 0,
        is_call: 0,
        is_audio: 0,
        check_fps: 0,
    }
};
export const videoMixin = {
    data() {
        return {
            list: [],
            name: '',
            searchList: [],
            changeNameA: false,
            topText: '',
            menuLoading: false,
            menuText: '数据加载中',
            studentList: [],
            setTimeoutPageObj: null,
            canSetTimeoutPage: true,
            setTimeoutPageTime: 0,//自动翻页时间 单位分钟
            setTimeoutPageOptions: [{
                label: '翻页时间设置',
                options: [{
                    value: 0,
                    label: '关闭翻页'
                },{
                    value: 1,
                    label: '一分钟'
                }, {
                    value: 2,
                    label: '两分钟'
                }, {
                    value: 5,
                    label: '五分钟'
                }, {
                    value: 10,
                    label: '十分钟'
                }, {
                    value: 15,
                    label: '一刻钟'
                }, {
                    value: 30,
                    label: '半小时'
                }]
            }],
            showStudentList: [],
            subscribeAudioStudentList: {},
            channelArr: [],
            pageSize: 9,
            pageNum: 1,
            recordPageNum: 0,
            recordPageSize: 15,
            videoSize: 0,
            showVideo: false,
            topLoading: false,
            userPageId: 0,
            userId: 0,
            subjectId: 0,
            subjectUuid: 0,
            examUuid: 0,
            messageUnCount: 0,
            top_video: false,
            topAliWebrtc: {},
            student: studentStartInfo,
            aliWebrtcArr: [],
            userInfo: window.localStorage.getItem('index-userInfo'),
            exam_subject_video_number: "考试名称+考试科目+监控组号", //标题
            total: 0, // 数据总条数
            totalPage: 1, // 总页数
            checkboxGroup: 1,
            chooseArray: selectOptions,
            all_call: false,
            loadingMessage: false,
            noMessage: false,
            stomp: {},
            socket: {},
            teacherId: localStorage.getItem('index-teacherId'),
            messageList: [],
            abnormalMessageList: [],
            userVideoList: ["front", "back"],
            userVideoListTest: {
                "front": "前置",
                "back": "后置"
            },
            userVideoListOne: ["front"],
            topKeyArr: {},
            dialogVisibleFu: false, // 控制发送消息弹框显示与隐藏
            dataInfo: {},
            userKey: '', // 当前发送消息的本地缓存key
            userType: '', // 正、反面监控  front-正面  back-反面
            all_send: false, // 发送消息 true-全体 false-单个
            msgSingleObj: {}, // 发送的单个消息对象列表
            msgAllList: [], // 发送的全体消息列表
            aliWebrtc_tea: {}, //老师aliWebrtc
            isshowMe: false, //是否显示老师自己的摄像头
            imgwidth: 50, //老师自己
            imgheight: 50,

            optionRtc: {
                isReceiveOnly: configApp.rtc.canCameraPublish,
            },

            //调试按钮
            // testStatus: 0,
            queryTestStatus: {
                zkzNum: null,
                subjectUuid: null,
            },
            hasHorizontalTop: true,
            // 填码通道
            radioCode: 0,
            // 交卷通道
            radioPaper: 0,
            refreshTimeArr: [1, 1, 2, 2, 2, 4, 4, 4, 4, 8, 8, 8, 8, 8, 16, 16, 16, 16, 16, 16, 20],//重新连接的时间间隔，单位秒，最大的是默认是20s
            refreshSocketTimeout: 0,//自动重连的定时器
            refreshSocket: 0,//自动重连的定时器

            showStudentDialog:false,
            isNowPage:true,
            isChangeStatus:0
        };
    },
    created() {
        localForage.getItem("topKeyArr").then((res) => {
            this.topKeyArr = res ? res : {};
        });
    },
    mounted() {
        let msgSingleObj = window.localStorage.getItem('index_monitor_single_msg');
        let msgAllList = window.localStorage.getItem('index_monitor_all_msg');
        if (msgSingleObj) {
            this.msgSingleObj = JSON.parse(msgSingleObj);
        }
        if (msgAllList) {
            this.msgSingleObj = JSON.parse(msgAllList);
        }
        // this.subjectId = this.$route.query['subjectId'];
        // this.subjectUuid = this.$route.query['subjectUuid'];
        // this.examUuid = this.$route.query['examUuid'];
        this.subjectId = window.localStorage.getItem('subjectId')
        this.subjectUuid = window.localStorage.getItem('subjectUuid')
        this.examUuid = window.localStorage.getItem('examUuid')

        if (!this.subjectId || !this.subjectUuid) {
            this.$router.go(-1);
        }

        let aliWebrtc = new AliRtcEngine();
        /**
         * AliWebRTC isSupport检测
         */
        aliWebrtc.enableCamera = configApp.rtc.canCameraPublish;
        aliWebrtc.isSupport(this.optionRtc).then(re => {
            this.getList();
            this.initSocket();
            this.getChatRecord();
        }).catch(error => {
            this.$notify.error({
                title: '错误',
                message: error.message
            });
        })
        let videoRoot = this.$refs.videoRoot.$el;
        let availHeight = videoRoot.offsetWidth;
        let availWidth = videoRoot.offsetHeight;
        if (availWidth / availHeight < 0.62) {
            this.hasHorizontalTop = false;
        }
    },
    methods: {
        // 填码通道
        async changeHandlerCode(value, item) {
            console.log(this.subjectUuid)
            const confirmResult = await this.$confirm(`确认开启考生：${item.name} 的填码通道？`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                closeOnClickModal: false,
            }).catch((err) => err);

            if (confirmResult !== "confirm")
                return this.$message.info("您取消了");
            this.radioCode = value
            recordChannel({
                zkzNum: item.zkzNum,
                subjectUuid: this.subjectUuid,
                status: 0
            }).then(res => {
                if (res.data.code == 200) {return this.$message.success("操作成功")}
            })
            // 局部刷新
            item.codeStatus = value
        },
        // 交卷通道
        async changeHandlerPaper(value,item) {
            const confirmResult = await this.$confirm(`确认开启考生：${item.name} 的交卷通道？`, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                closeOnClickModal: false,
            }).catch((err) => err);

            if (confirmResult !== "confirm")
                return this.$message.info("您取消了");
            this.radioPaper = value
            recordChannel({
                zkzNum: item.zkzNum,
                subjectUuid: this.subjectUuid,
                status: 1
            }).then(res => {
                if (res.data.code == 200) {return this.$message.success("操作成功")}
            })
            // 局部刷新
            item.handPaperStatus = value
        },
        // 关闭监控（踢人）
        closeMonitor(item) {
            // console.log(item)
            let channelId = item.channelId
            let userId = item.zkzNum + '_front,' + item.zkzNum + '_back'
            removeUser(this.examUuid, this.subjectUuid, channelId, userId).then((res) => {
                if (res.data.code != 200) {
                    return this.$message.error(res.data.message);
                }
                this.$message.success('操作成功');
            });
        },
        showStudentDialogShow(zkzNum,isChangeStatus = 1){
            isChangeStatus && this.changeStatus(zkzNum);
            this.canSetTimeoutPage = false;
            this.setTimeoutPage();
            let userId = this.subjectUuid + "_" + zkzNum + "_" + "front";
            let student = this.getPagePublisher(userId);
            if (student) {
                this.isPagePublisher = 1;
            } else {
                this.isPagePublisher = 0;
                student = this.getPublisher(userId);
            }
            if (student) {
                if(!this.isNowPage || this.isPagePublisher === 0){
                    this.showStudent = student;
                    this.showStudentDialog = true;
                }
                this.is_check = 1;
                student.is_check = 1;
                this.isChangeStatus = isChangeStatus;
                this.studentList[student["key"]] = student;
                if (this.isPagePublisher == 1 && this.showStudentList[student["newKey"]]) {
                    this.showStudentList[student["newKey"]] = student;
                }
                if (this.isPagePublisher == 0 || !this.isNowPage) {
                    let fix = this.subjectUuid + "_" + student.zkzNum;
                    //重新订阅流
                    for (let v = 0; v < this.userVideoList.length; v++) {
                        let userId1 = fix + "_" + this.userVideoList[v];
                        if (student[userId1].is_join == 1) {
                            this.$nextTick(() => {
                                this.subscribeUser(userId1, student, 1, false);
                            });
                        }
                    }
                }
            }
        },
        hideStudentDialogShow(zkzNum){
            this.$nextTick(() => {
                this.canSetTimeoutPage = true;
                this.setTimeoutPage();
                let userId = this.subjectUuid + "_" + zkzNum + "_" + "front";
                let student = this.getPublisher(userId);
                if (student) {
                    this.is_check = 0;
                    this.showStudent = null;
                    this.showStudentDialog = false;
                    this.isChangeStatus = 0;
                    student.is_check = 0;
                    let key = student["key"];
                    this.studentList[key] = student;
                    if (
                        this.isPagePublisher == 1 &&
                        this.showStudentList[student["newKey"]]
                    ) {
                        this.showStudentList[student["newKey"]] = student;
                    }
                    if (!this.isNowPage || this.isPagePublisher == 0) {
                        let fix = this.subjectUuid + "_" + zkzNum;
                        //重新订阅流
                        for (let v = 0; v < this.userVideoList.length; v++) {
                            let userId1 = fix + "_" + this.userVideoList[v];
                            if (student[userId1].is_join == 1) {
                                let is_call = student[userId1].is_call;
                                let is_audio = student[userId1].is_audio;
                                this.$nextTick(() => {
                                    if (is_audio) {
                                        this.unSubscribeUser(userId1, student);
                                    }
                                    if (is_call) {
                                        this.callOneStudent(userId1, student, 2);
                                    }
                                });
                            }
                        }
                        this.isPagePublisher = 1;
                        this.$forceUpdate();
                    }
                }
            });
        },
        // 置顶数组
        Totop(userId) {
            let student = this.getPublisher(userId);
            if (student) {
                let key = student['key'];
                let topKey = this.studentList[0]['topKey'];
                this.studentList[key].topKey = ++topKey;
                if(this.pageNum > 1){
                    this.is_check = 0;
                    this.studentList[key].is_check = 0;
                    this.isPagePublisher = 0;
                    this.showStudent = null;
                    this.showStudentDialog = false;
                    this.isChangeStatus = 0;
                    this.pageNum = 1;
                }
                this.$nextTick(()=>{
                    this.studentList.sort((a, b) => {
                        return b.topKey - a.topKey;
                    });
                    this.topKeyArr[student.zkzNum] = topKey;
                    localForage.setItem("topKeyArr", this.topKeyArr);
                    this.reSortStudentList(this.studentList);
                    this.changeStudentVideoList();
                    this.$forceUpdate();
                })

            }
        },

        reSortStudentList(studentList) {
            let key = 0;
            let list = [];
            for (let i = 0; i < studentList.length; i++) {
                let temp = studentList[i];
                temp['key'] = key;
                key = ++key;
                list.push(temp);
            }
            this.studentList = list;
        },


        //获取当前监控组的所有学生
        async getList() {
            this.topLoading = true;
            this.menuLoading = true;
            this.topText = '数据加载中';
            let {
                data
            } = await getStudentsAlic(this.subjectId, this.examUuid);
            this.topLoading = false;
            this.menuLoading = false;
            this.topText = '';
            if (data && data.length > 0) {
                this.list = data;
                this.videoSize = parseInt(data[0].videoSize);
                if (this.videoSize === 1) {
                    this.userVideoList = this.userVideoListOne;
                }
                this.createRtc();
            }
        },
        //初始化aliwebrtc对象
        createRtc() {
            this.studentList = [];
            let studentList = [];
            let list = this.list;
            let key = 0;
            let topKeyArr = this.topKeyArr;
            for (let i = 0; i < list.length; i++) {
                let aliWebrtc = new AliRtcEngine();
                aliWebrtc.configLocalCameraPublish = configApp.rtc.canCameraPublish;
                aliWebrtc.enableAudioVolumeIndicator = false;
                let config = JSON.parse(list[i].token);
                let channelId = config.channelId;
                this.aliWebrtcArr[channelId] = aliWebrtc;
                this.channelArr[channelId] = {
                    is_publish: 0,
                    config: config
                };
                let data = list[i][channelId];
                if(data.length > 1){
                    this.$notify.error({
                        title: '错误',
                        message: "频道人数超过上限(ID:"+channelId+")"
                    });
                    return false;
                }
                let tempData = [];
                let userVideoList = this.userVideoList;
                for (let j = 0; j < data.length; j++) {
                    let temp = data[j];
                    let fix = this.subjectUuid + '_' + temp.zkzNum;
                    for (let v = 0; v < userVideoList.length; v++) {
                        temp[fix + '_' + userVideoList[v]] = {
                            is_join: 0,//是否入会
                            is_video: 0,//是否有订阅视频流
                            is_call: 0,//是否和学生通话
                            is_audio: 0,//是否听学生声音
                            check_fps: 0,
                        };
                    }
                    temp['key'] = key;
                    temp['topKey'] = 0;
                    if (topKeyArr && temp.zkzNum && topKeyArr[temp.zkzNum]) {
                        temp['topKey'] = topKeyArr[temp.zkzNum];
                    }
                    temp['abnormal'] = 0;
                    temp['is_check'] = 0;
                    tempData.push(temp);
                    key = ++key;
                }
                studentList = studentList.concat(tempData);
                //加入房间
                this.joinRoom(channelId, config);
                //监听事件
                try{
                    this.initRtc(channelId);
                }catch (e) {

                }
            }
            studentList.sort((a, b) => {
                return b.topKey - a.topKey;
            });
            this.reSortStudentList(studentList);
            this.channelArr = this.removePropertyOfNull(this.channelArr);
            this.aliWebrtcArr = this.removePropertyOfNull(this.aliWebrtcArr);
            this.total = this.studentList.length;
            this.totalPage = Math.ceil(this.total / this.pageSize);
            let start = (this.pageNum - 1) * this.pageSize;
            let end = this.pageNum * this.pageSize;
            this.searchList = this.studentList;
            this.showStudentList = this.studentList.slice(start, end);
            let times = localStorage.getItem("setTimeoutPageTime",this.setTimeoutPageTime+"");
            if(times){
                this.setTimeoutPageTime = parseInt(times);
            }
            this.setTimeoutPage();
        },
        //自动翻页
        setTimeoutPage(){
            if(this.showControl != 0){
                this.canSetTimeoutPage = false;
            }
            this.setTimeoutPageObj && clearTimeout(this.setTimeoutPageObj);
            this.setTimeoutPageObj = null;

            if(!this.canSetTimeoutPage){
                return false;
            }
            localStorage.setItem("setTimeoutPageTime",this.setTimeoutPageTime+"");
            if(this.setTimeoutPageTime > 0){
                this.setTimeoutPageObj = setTimeout(()=>{
                    this.setTimeoutPageObj && clearTimeout(this.setTimeoutPageObj);
                    let newNum = this.pageNum;
                    newNum = ++newNum;
                    if(newNum > this.totalPage){
                        newNum = 1;
                    }
                    this.handleCurrentChange(newNum);
                },this.setTimeoutPageTime*60000);
            }
        },
        //aliwebrtc的事件监听
        initRtc(channelId) {
            let aliWebrtc = this.aliWebrtcArr[channelId];
            /**
             * remote用户加入房间 onJoin
             * 更新在线用户列表
             */
            aliWebrtc.on("onJoin", (publisher) => {
                let userId = publisher.userId;
                if (userId) {
                    this.updateUserJoinList(userId);//学生入会更新状态
                }
            });
            /**
             * remote流发布事件 onPublish
             * 将该用户新增到推流列表
             * 若该用户已存在推流列表，则进行状态更新
             */
            aliWebrtc.on("onPublisher", (publisher) => {
                let userId = publisher.userId;
                if (userId) {
                    this.subscribeUser(userId);//订阅学生流
                    this.checkHasCallUser(userId);//判断和那些学生正在通话恢复通话
                    this.updateUserAbnormal(userId);//更新学生两路视频异常状态
                    this.monitorNumListener(userId);//向服务端发送在线日志
                }
            });

            /**
             * remote流结束发布事件 onUnPublisher
             * 推流列表删除该用户
             * 移除用户视图
             * 初始化订阅状态
             */
            aliWebrtc.on("onUnPublisher", (publisher) => {
                let userId = publisher.userId;
                if (userId) {
                    this.unSubscribePublisher(userId);//更新学生订阅的状态
                    this.unPublisherErrMessage(userId);//更新学生监控异常的错误日志
                }
            });

            /**
             * 被服务器踢出或者频道关闭时回调 onBye
             */
            aliWebrtc.on("onBye", (message) => {
                //1:被服务器踢出
                //2:频道关闭
                //3:同一个ID在其他端登录,被服务器踢出
                let msg;
                switch (message.code) {
                    case 1:
                        msg = "被服务器踢出";
                        // 清除本地的 token
                        //window.localStorage.clear();
                        // 使用编程式导航跳转到登录页面
                        //this.$router.push("/login");
                        break;
                    case 2:
                        msg = "频道关闭";
                        break;
                    case 3:
                        msg = "同一个ID在其他端登录,被服务器踢出";
                        break;
                    default:
                        msg = "onBye";
                }
                console.log("onBye",msg);
            });

            /**
             *  错误信息
             */
            aliWebrtc.on("onError", (error) => {
                //this.getList();
                let msg = error && error.message ? error.message : error;
                if (msg && msg.indexOf("no session") > -1) {
                    msg = "请重新登录：" + msg;
                }
                if (error.errorCode === 10011 || error.errorCode === 10012) {
                    msg = error.errorCode === 10011 ? "屏幕共享被禁止" : "屏幕共享已取消";
                    // setTimeout(() => {
                    //   $("#screenPublish").removeAttr("checked");
                    //   getPublishState("danger");
                    // }, 2000);
                }

                if (error.code == 15) {
                    msg = "没有开启H5兼容";
                }
                if (error.type === "publish") {
                    // 提示用户网络状态不佳
                    // console.log("推流断开 需要停止推流,然后重新推流");
                    this.publishAudio(channelId);
                }
                if (error.type === "subscribe") {
                    //console.log("订阅断开 取消订阅该userId的所有订阅并移除所有该userId的dom");
                    //先记录当前用户的订阅状态
                    //let subInfo = this.getSubscribeInfo(index,error.userId);
                    //取消订阅状态
                    let userId = error.userId;
                    if (userId) {
                        let student = this.getPublisher(userId);
                        if (student && student[userId].is_video) {
                            let type = 1;
                            if(this.userId === userId){
                                type = 2;
                            }
                            this.subscribeUser(userId,student, type, !!student[userId].is_audio);
                        }
                    }
                }
                // this.joinRoom(channelId);
                // console.log('ttt--->' + error.errorCode)
                console.log('onError' , error)
                // console.log(JSON.stringify(error))
            });

            /**
             * 检测到用户离开频道
             * 更新用户列表
             * 移除用户视图
             */
            aliWebrtc.on("onLeave", (publisher) => {
                let userId = publisher.userId;
                this.delSubscribe(userId);
                let _that = this;
                if (userId) {
                    setTimeout(function () {
                        let studentLeave = _that.getPublisher(userId);
                        if (studentLeave && studentLeave[userId]) {
                            let isJoin = studentLeave[userId].is_join
                            if (isJoin == '0') {
                                console.log("退出了...")
                                localStorage.setItem(userId, "0")
                                let infoList = userId.split("_");
                                // 记录当前学生监控在线数
                                let monitorNumTotal = 0;
                                //  获取另外一个摄像头
                                if (userId.includes('front')) {
                                    // 获取另外一路状态
                                    let backNum = localStorage.getItem(infoList[0] + "_" + infoList[1] + "_back")
                                    if (backNum && backNum == '1') {
                                        monitorNumTotal++;
                                    }
                                }
                                if (userId.includes('back')) {
                                    // 获取另外一路状态
                                    let frontNum = localStorage.getItem(infoList[0] + "_" + infoList[1] + "_front")
                                    if (frontNum && frontNum == '1') {
                                        monitorNumTotal++;
                                    }
                                }

                                monitorNumListener({
                                    zkzNum: infoList[1],
                                    subjectUuid: infoList[0],
                                    camera: infoList[2],
                                    monitorNum: monitorNumTotal,
                                    status: 0
                                }).then(res => {
                                    if (res.data.code != 200) {
                                        //console.log(res.data.message);
                                    }
                                })
                            }
                        }
                    }, 2000)
                }
            })

            aliWebrtc.on("onMedia", (data) => {
                let userId = data.userId;
                let student = this.getPublisher(userId);
                if (student && student[data.userId].is_video) {
                    if(data.data.camera && data.data.audio.level === 0){
                        //判断当前视频流的fps
                        if(data.data.camera.fps === 0 && data.data.bytesReceivedPerSecond === 0 && student[userId].check_fps){
                            let type = 1;
                            if(this.userId === userId){
                                type = 2;
                            }
                            let userInfo = aliWebrtc.getUserInfo(userId);
                            this.subscribeUser(userId,student, type, !!student[data.userId].is_audio);
                            console.log(userInfo,"onMedia-check_fps-1",userId);
                            console.log(data.data,"onMedia-check_fps-0",userId);
                        }
                    }
                }
            });
        },

        monitorNumListener(userId){
            let aa = setTimeout( ()=> {
                aa && clearTimeout(aa);
                let student = this.getPublisher(userId);
                if (student && student[userId] && student[userId].is_join) {
                    let status = localStorage.getItem(userId)
                    // 如果status为1 则是刷新 不是正常进入
                    if (status && status == '1') {

                    } else {
                        let isJoin = student[userId].is_join
                        if (isJoin && isJoin == '1') {
                            localStorage.setItem(userId, "1")
                            // 调用后台接口 记录该学生登录成功
                            console.log("登陆成功!!!!!!!!")
                            let infoList = userId.split("_");
                            // 记录当前学生监控在线数
                            let monitorNumTotal = 0;
                            //  获取另外一个摄像头
                            if (userId.includes('front')) {
                                monitorNumTotal = 1;
                                // 获取另外一路状态
                                let backNum = localStorage.getItem(infoList[0] + "_" + infoList[1] + "_back")
                                if (backNum && backNum == '1') {
                                    monitorNumTotal++;
                                }
                            }
                            if (userId.includes('back')) {
                                monitorNumTotal = 1;
                                // 获取另外一路状态
                                let frontNum = localStorage.getItem(infoList[0] + "_" + infoList[1] + "_front")
                                if (frontNum && frontNum == '1') {
                                    monitorNumTotal++;
                                }
                            }

                            monitorNumListener({
                                zkzNum: infoList[1],
                                subjectUuid: infoList[0],
                                camera: infoList[2],
                                monitorNum: monitorNumTotal,
                                status: 1
                            }).then(res => {
                                if (res.data.code != 200) {
                                    console.log(res.data.message);
                                }
                            })
                        }
                    }
                }
            }, 2000)
        },
        //取消订阅状态
        delSubscribe(userId) {
            let student = this.getPublisher(userId);
            if (student) {
                let key = student['key'];
                student[userId].is_join = 0;
                student[userId].is_video = 0;
                student[userId].is_call = 0;
                student[userId].is_audio = 0;
                student[userId].check_fps = 0;
                this.studentList[key] = student;
                if (this.showStudentList[student['newKey']]) {
                    this.showStudentList[student['newKey']] = student;
                }
                this.$forceUpdate();
            }
        },
        //清除对象的空对象
        removePropertyOfNull(obj) {
            let _newPar = {};
            for (let key in obj) {
                //如果对象属性的值不为空，就保存该属性（这里我做了限制，如果属性的值为0，保存该属性。如果属性的值全部是空格，属于为空。
                if ((obj[key] === 0 || obj[key] === false || obj[key]) && obj[key].toString().replace(/(^\s*)|(\s*$)/g, '') !== '') {
                    _newPar[key] = obj[key];
                }
            }
            return _newPar;
        },
        checkUserId(userId) {
            if (!userId) {
                return false;
            }
            let info = {};
            let userArr = userId.split('_');
            if (userArr.length === 0) {
                return false;
            }
            //学生
            if (userArr.length === 3) {
                info.type = 1;
                let videoType = userArr[2];
                info.videoType = videoType;
                let index = this.userVideoList.indexOf(videoType);
                if (index > -1) {
                    let userVideoList = JSON.parse(JSON.stringify(this.userVideoList));
                    delete userVideoList[index];
                    userVideoList = userVideoList.sort();
                    info.videoToType = userVideoList[0] ? userVideoList[0] : this.userVideoList[index];
                    info.videoToUserId = userArr[0] + "_" + userArr[1] + "_" + info.videoToType;
                }
            }
            //监控老师
            if (userArr.length === 2) {
                info.type = 2;
            }
            info.userId = userId;
            return info;
        },
        unPublisherErrMessage(userId) {
            let userInfo = this.checkUserId(userId);
            if (userInfo.type === 1) {
                this.abnormalMessageList.push(userInfo);
            }
            //console.log(this.abnormalMessageList);
        },
        // 调试状态改变
        testStatusChange(item,index) {
            // return
            // console.log(item,'item')
            this.$confirm('此操作将标记报到检查状态, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.queryTestStatus.subjectUuid = this.subjectUuid
                this.queryTestStatus.zkzNum = item.zkzNum

                updateTestStatus(this.queryTestStatus).then((res) => {
                    // console.log(res, "updateTestStatus");
                    if (res.data.code != 200) {
                        return this.$message.error(res.data.message);
                    }

                    this.showStudentList[index]["testStatus"]=1
                    this.$message.success(res.data.message);
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            })
        },

        // 打开发送消息弹框
        msgShow(type, data, userType) {
            let userKey = this.examUuid + "_" + this.subjectUuid + "_" + this.userInfo;
            if (type == 0) {
                // 全体
                this.all_send = true;
            } else if (type == 1) {
                // 单个
                this.all_send = false;
                this.dataInfo = data;
                userKey += '_' + this.dataInfo.zkzNum + '_' + userType;
                this.canSetTimeoutPage = false;
                this.setTimeoutPage();
            }
            this.userKey = userKey;
            this.userType = userType;
            this.dialogVisibleFu = true;
        },
        // 关闭弹框
        closeDialog(value, type, msg) {
            this.dialogVisibleFu = value;
            this.canSetTimeoutPage = true;
            this.setTimeoutPage();
            if (type === true) {
                this.sendAllStudentMessage(msg);
            }
        },
        // //发送给所有学生
        sendAllStudentMessage(msg) {
            let message = {};
            let userId = "";
            let msgInfo = {};
            message.msg = msg;
            msgInfo.msg = msg;
            if (this.all_send) {
                // 全体
                message.type = 2;
                message = JSON.stringify(message);
                this.sendMessage('/pubChat', message);
                msgInfo.time = formatTime(new Date());
                this.msgAllList.push(msgInfo);
            } else {
                // 单个
                message.type = 3;
                userId = this.subjectUuid + '_' + this.dataInfo.zkzNum + '_' + this.userType;
                message = JSON.stringify(message);
                this.sendMessage('/pubChat', message, [userId]);
                msgInfo.time = formatTime(new Date());
                if (this.msgSingleObj[this.dataInfo.zkzNum]) {
                    this.msgSingleObj[this.dataInfo.zkzNum].push(msgInfo);
                } else {
                    this.msgSingleObj[this.dataInfo.zkzNum] = [];
                    this.msgSingleObj[this.dataInfo.zkzNum].push(msgInfo);
                }
            }
            let dataMessage = {
                "message": msg,
                "time": formatTime(new Date()),
            }
            localForage.getItem(this.userKey).then((list) => {
                if (list) {
                    list.push(dataMessage);
                } else {
                    list = [dataMessage];
                }
                localForage.setItem(this.userKey, list).then(() => {
                });
            });
        },

        //获取学生举手记录
        async getChatRecord() {
            // console.log(this.subjectUuid=='',"返回subjectUuid  jjjj")
            if (this.loadingMessage || this.noMessage || this.subjectUuid == '') {
                return;
            }
            this.loadingMessage = true;
            let {
                data
            } = await listChatRecord(this.subjectUuid, this.recordPageNum, this.recordPageSize);
            if (data.code == 200) {
                data = data.data;
                this.loadingMessage = false;
                let list = data.list;
                if ((list.length == 0 || list.length < this.recordPageSize) && this.recordPageNum > 1) {
                    this.noMessage = true;
                    return;
                }
                this.messageUnCount = 0;
                let messageList = list.map((val) => {
                    let message = null;
                    try {
                        message = JSON.parse(val.message);
                    } catch (e) {
                    }
                    if (message && (!message.type || message.type == 1)) {
                        message.isRead = parseInt(val.isRead);
                        if (message.isRead === 0) {
                            ++this.messageUnCount;
                        }
                        message.id = val.id;
                        return message;
                    }
                });
                this.messageList = this.messageList.concat(messageList.filter(Boolean));
            }
        },
        //加载更多的学生举手记录
        async loadMessage() {
            ++this.recordPageNum;
            await this.getChatRecord();
        },
        /**
         * 和所有学生通话
         * @param call_type 0正常订阅模式 2-取消订阅所有学生
         */
        callAllStudent(call_type = 0) {
            if (call_type === 2) {
                this.all_call = false;
            } else {
                this.all_call = !this.all_call;
                this.canSetTimeoutPage = !this.all_call;
                this.setTimeoutPage();
            }
            let is_call = this.all_call ? 1 : 0;
            let studentList = this.studentList;
            let channelArr = {};
            this.subscribeAudioStudentList = {};
            for (let i = 0; i < studentList.length; i++) {
                let student = studentList[i];
                let userId = this.subjectUuid + '_' + student.zkzNum;
                let userVideo = this.checkStudentVideo(userId, student, false);
                if (!userVideo) {
                    continue;
                }
                userId = this.subjectUuid + '_' + student.zkzNum + '_' + userVideo;
                // if (student[userId].is_call == is_call) {
                //     continue;
                // }
                if (!channelArr[student['channelId']]) {
                    channelArr[student['channelId']] = [];
                    channelArr[student['channelId']][0] = userId;
                } else {
                    channelArr[student['channelId']].push(userId);
                }
                let aliWebrtc = this.aliWebrtcArr[student['channelId']];
                aliWebrtc.muteLocalCamera(this.all_call);
                this.teacherPublishUpStudentCall(userId, student, is_call, false);
            }
            if (channelArr) {
                for (const channelId in channelArr) {
                    if (this.channelArr[channelId].is_publish === 1) {
                        this.sendCallAllSocket(channelId, is_call, channelArr[channelId]);
                    } else {
                        this.publishAudio(channelId, !is_call, (res) => {
                            if (res) {
                                this.sendCallAllSocket(channelId, is_call, channelArr[channelId]);
                            } else {
                                this.$notify.error({
                                    title: '错误',
                                    message: '你的声音发布失败，请刷新重新重试'
                                });
                            }
                        });
                    }
                }
            }
        },
        /**
         * 向所有学生发送通知
         * @param channelId
         * @param type
         * @param receiver
         */
        sendCallAllSocket(channelId, type = 0, receiver = '') {
            let message = {
                channelId: channelId,
                teacherId: this.teacherId,
            };
            message.type = type;
            message = JSON.stringify(message);
            this.sendMessage('/pubChat', message, receiver);
        },
        //和单个学生通话 开启或关闭和学生建立连接
        callOneStudent(userId, student, type = 0) {
            if (!student) {
                student = this.getPublisher(userId);
            }
            let is_call = student[userId].is_call;
            if (type === 1 && is_call == 1) {
                return false;
            }
            if(type !== 2){
                is_call = is_call ? 0 : 1;
            }else {
                if(is_call === 0){
                    return false;
                }
                is_call = 0;
            }
            let aliWebrtc = this.aliWebrtcArr[student.channelId];
            aliWebrtc.muteLocalCamera(!is_call);
            this.canSetTimeoutPage = !is_call;
            this.setTimeoutPage();
            if (this.channelArr[student.channelId].is_publish === 1) {
                if (is_call) {
                    this.subscribeAudioStudentList[userId] = 1;
                } else {
                    if (this.subscribeAudioStudentList[userId]) {
                        delete this.subscribeAudioStudentList[userId];
                    }
                }
                this.aliWebrtcArr = this.removePropertyOfNull(this.aliWebrtcArr);
                this.teacherPublishUpStudentCall(userId, student, is_call);
            } else {
                this.publishAudio(student.channelId, !is_call, (res) => {
                    if (res) {
                        if (is_call) {
                            this.subscribeAudioStudentList[userId] = 1;
                        } else {
                            if (this.subscribeAudioStudentList[userId]) {
                                delete this.subscribeAudioStudentList[userId];
                            }
                        }
                        this.aliWebrtcArr = this.removePropertyOfNull(this.aliWebrtcArr);
                        this.teacherPublishUpStudentCall(userId, student, is_call);
                    } else {
                        this.$notify.error({
                            title: '错误',
                            message: '你的声音发布失败，请刷新重新重试'
                        });
                    }
                })
            }
            // let teacherId = this.teacherId+"_"+student.channelId
            // let teacherInfo = aliWebrtc.getUserInfo(teacherId);

        },
        //初始化 webSocket
        initSocket() {
            if (this.refreshSocketTimeout) {
                return true;
            }
            if (typeof (WebSocket) == "undefined") {
                this.$message({
                    showClose: true,
                    message: '您的浏览器不支持WebSocket',
                    type: 'error'
                });
            } else {
                //let url = "https://socket-tcp.thussat.com/xechat/"
                let url = "https://socket-qhf.thussat.net/xechat"
                let socket = new SockJS(url,null,{timeout:2000});
                this.socket = socket;
                let stomp = Stomp.over(socket);
                stomp.debug = null;
                this.stomp = stomp;
                // 每隔30秒做一次心跳检测
                stomp.heartbeat.outgoing = 5000;
                // 客户端不接收服务器的心跳检测
                stomp.heartbeat.incoming = 1;
                const user = {
                    'username': this.teacherId,
                    'token': localStorage.getItem("index-token"),
                    'type': 't'
                };
                //连接
                stomp.connect(user, (frame) => {
                    //订阅广播
                    // stomp.subscribe("topic/chatRoom", function (res) {
                    //     console.log("top/chatRoom");
                    //     console.log(res.body);
                    // });
                    this.refreshSocket = 0;
                    this.refreshSocketTimeout && clearTimeout(this.refreshSocketTimeout);
                    //用户模式
                    stomp.subscribe("/user/" + this.teacherId + "/subChat", (res) => {
                        let data = JSON.parse(res.body);
                        if (data.code == 200) {
                            let message = JSON.parse(data.data.message);
                            message.isRead = 0;
                            message.id = data.data.messageId;
                            let messageType = message.type;
                            switch (messageType) {
                                case 1:
                                    this.messageUnCount = ++this.messageUnCount;
                                    this.messageList.unshift(message);
                                    this.noMessage = false;
                                    break;
                                case 2:
                                    let userId = data.data.user.username;
                                    // console.log(user_info);
                                    break;
                            }

                            // console.log(this.messageUnCount, "消息条数")
                        }
                    });
                }, () => {
                    this.refreshSocket = ++this.refreshSocket;
                    let refreshTimeArr = this.refreshTimeArr;
                    let time = refreshTimeArr[this.refreshSocket - 1];
                    time = time ? time : refreshTimeArr[refreshTimeArr.length - 1];
                    this.refreshSocketTimeout = setTimeout(async () => {
                        this.refreshSocketTimeout && clearTimeout(this.refreshSocketTimeout);
                        this.refreshSocketTimeout = null;
                        this.socket = null;
                        this.initSocket();
                    }, time * 1000);
                });
            }
        },
        checkStudentVideo(userid, student = null, is_alert = true) {
            if (!student) {
                student = this.getPublisher(userid);
            }
            if (!student || !student.name) {
                is_alert && this.$notify.error({
                    title: '错误',
                    message: '视频未开启连接'
                });
                return false;
            }
            let is_join = 0;
            let video = '';
            let userVideoList = this.userVideoList;
            for (let i = 0; i < userVideoList.length; i++) {
                let userVideo = userVideoList[i];
                if (student[this.subjectUuid + '_' + student.zkzNum + '_' + userVideo].is_join == 1) {
                    is_join = 1;
                    video = userVideo;
                    break;
                }
            }
            if (is_join == 0) {
                is_alert && this.$notify.error({
                    title: '错误',
                    message: student.name + '视频未开启连接'
                });
                return is_join;
            }
            return video;
        },
        //处理 学生通话 查看按钮事件
        showVideoSocket(messageKey, channelId, userid) {
            let message = this.messageList[messageKey];
            let student = this.getPublisher(userid);
            //检查是否有视频在线
            let hasStudentVideo = this.checkStudentVideo(userid, student);
            if (!hasStudentVideo) {
                return false;
            }
            userid = this.subjectUuid + '_' + student.zkzNum + '_' + hasStudentVideo;
            if (student) {
                this.showVideoDo(userid, student);
                this.messageList[messageKey].isRead = 1;
                this.messageUnCount = --this.messageUnCount;
                if (this.messageUnCount < 0) {
                    this.messageUnCount = 0;
                }
                signMessageReadable(message.id);
            } else {
                this.$notify.error({
                    title: '错误',
                    message: '视频未开启连接'
                });
            }
        },
        //处理 学生通话 处理按钮事件
        showVideoAndCall(messageKey, userid) {
            let message = this.messageList[messageKey];
            let student = this.getPublisher(userid);
            let hasStudentVideo = this.checkStudentVideo(userid, student);
            if (!hasStudentVideo) {
                return false;
            }
            userid = this.subjectUuid + '_' + student.zkzNum + '_' + hasStudentVideo;
            if (student) {
                this.showVideoDo(userid, student, true);
                this.callOneStudent(userid, student, 1);
                signMessageReadable(message.id);
                this.messageList[messageKey].isRead = 1;
                this.messageUnCount = --this.messageUnCount;
                if (this.messageUnCount < 0) {
                    this.messageUnCount = 0;
                }
            } else {
                this.$notify.error({
                    title: '错误',
                    message: '视频未开启连接'
                });
            }
        },
        //发送Socket 消息
        sendMessage(cmd, message, receiver = '') {
            let data = {
                "message": message
            };
            if (receiver) {
                data.receiver = receiver;
            }
            data.subjectUuid = this.subjectUuid;
            data = JSON.stringify(data);
            // console.log(data)
            this.stomp.send(cmd, {}, data);
        },
        //侧边栏视频放大事件
        showVideoDo(userId, student, has_subscribeAudio = false) {
            let video = this.$refs['video_' + userId] ? this.$refs['video_' + userId][0] : false;
            let index = student['index'];
            let key = student['key'];
            //当前视频在当前分页中
            this.showVideo = false;
            if (video) {
                this.userId = 0;
                this.student = studentStartInfo;
                this.showTopVideo(userId);
                if (has_subscribeAudio) {
                    this.subscribeAudio(userId, student, has_subscribeAudio);
                }
            } else {
                //判断当前视频是否在该频道
                this.userId = userId;
                this.student = this.studentList[key];
                this.topLoading = true;
                this.top_video = true;
                this.topText = '视频加载中';
                this.$nextTick(() => {
                    this.subscribeUser(userId, student, 2, has_subscribeAudio);
                    this.topLoading = false;
                })
            }
        },
        //视频缩小事件
        hideTopVideo() {
            if (window.document.exitFullscreen) {
                window.document.exitFullscreen().then((r) => {

                }).catch((err) => {
                    this.top_video = true;
                    this.showVideo = true;
                });
            } else if (window.document.mozCancelFullScreen) {
                window.document.mozCancelFullScreen();
            } else if (window.document.webkitCancelFullScreen) {
                window.document.webkitCancelFullScreen();
            } else if (window.document.msExitFullscreen) {
                window.document.msExitFullscreen();
            }

        },
        // 控制每页显示几条数据
        handleSizeChange(newSize) {
            this.pageSize = newSize;
            let maxPageNum = Math.ceil(this.total / this.pageSize);
            this.pageNum = this.pageNum > maxPageNum ? maxPageNum : this.pageNum;
            this.totalPage = Math.ceil(this.total / this.pageSize);
            this.setTimeoutPage();
            this.changeStudentVideoList();
        },
        // 控制显示第几页
        handleCurrentChange(newNum) {
            this.pageNum = newNum;
            this.changeStudentVideoList();
        },
        //页面改动重新订阅学生的音视频
        changeStudentVideoList(hasSubscribeUser = true) {
            this.callAllStudent(2);
            //取消订阅当前页面学生的视频流
            if (this.showStudentList) {
                let list = this.showStudentList;
                let userVideoList = this.userVideoList;
                for (let i = 0; i < list.length; i++) {
                    let student = list[i];
                    let fix = this.subjectUuid + '_' + student.zkzNum;
                    for (let v = 0; v < userVideoList.length; v++) {
                        if (student[fix + '_' + userVideoList[v]].is_join == 1) {
                            this.unSubscribeUser(fix + '_' + userVideoList[v], student);
                        }
                    }
                }
            }
            if(hasSubscribeUser){
                let start = (this.pageNum - 1) * this.pageSize;
                let end = this.pageNum * this.pageSize;
                this.showStudentList = this.studentList.slice(start, end);
                //重新订阅当前页面学生的视频流
                if (this.showStudentList) {
                    let list = this.showStudentList;
                    let userVideoList = this.userVideoList;
                    for (let i = 0; i < list.length; i++) {
                        let student = list[i];
                        let fix = this.subjectUuid + '_' + student.zkzNum;
                        //重新订阅流
                        for (let v = 0; v < userVideoList.length; v++) {
                            let userId = fix + '_' + userVideoList[v];
                            if (student[userId].is_join == 1) {
                                this.$nextTick(() => {
                                    this.subscribeUser(userId, student, 1, false);
                                })
                            }
                        }
                    }
                }
            }
        },
        unSubscribeUser(userId, student = false) {
            if (!student) {
                student = this.getPublisher(userId);
            }
            if (student && student[userId].is_video) {
                let aliWebrtc = this.aliWebrtcArr[student.channelId];
                aliWebrtc.configRemoteCameraTrack(userId, false, false);
                aliWebrtc.muteRemoteAudioPlaying(userId, true);
                aliWebrtc.configRemoteAudio(userId, false);
                aliWebrtc.setAudioVolume(userId, 1);
                aliWebrtc.subscribe(userId).then(res => {
                }).catch((error) => {
                    console.log(error,"userId:"+userId,"channelId:"+student['channelId'],"unSubscribeUser");
                })
                student[userId].is_video = 0;
                student[userId].is_audio = 0;
                student[userId].check_fps = 0;
                this.studentList[student['key']][userId] = student[userId];
                if (this.showStudentList[student['newKey']]) {
                    this.showStudentList[student['newKey']][userId] = student[userId];
                }
            }
        },
        //视频列表 视频放大事件
        showTopVideo(userId, type = 1) {
            this.userId = userId;
            let video;
            let studnet = null;
            if (type === 1) {
                this.userPageId = userId;
                this.userId = 0;
                this.student = studentStartInfo;
                video = this.$refs['video_' + userId] ? this.$refs['video_' + userId][0].parentNode : false;
                studnet = this.getPublisher(userId);
            } else {
                // console.log(11)
                this.topLoading = false;
                this.topText = '';
                video = this.$refs['top_video_' + userId] ? this.$refs['top_video_' + userId].parentNode : false;
            }
            if (this.showVideo) {
                this.imgwidth = 50;
                if (window.document.exitFullscreen) {
                    window.document.exitFullscreen();

                } else if (window.document.mozCancelFullScreen) {
                    window.document.mozCancelFullScreen();

                } else if (window.document.webkitCancelFullScreen) {
                    window.document.webkitCancelFullScreen();

                } else if (window.document.msExitFullscreen) {
                    window.document.msExitFullscreen();
                }

                // if (!!document.fullscreen ||
                //   document.mozFullScreen ||
                //   document.webkitIsFullScreen ||
                //   document.webkitFullScreen ||
                //   document.msFullScreen
                // ) {
                //   console.log(222)
                //   this.imgwidth = 50
                // }
            } else {
                // console.log(212)
                this.imgwidth = 250
                try {
                    if (video) {
                        if (video.requestFullscreen) {
                            video.requestFullscreen();
                            // this.imgwidth = 50
                        } else if (video.msRequestFullscreen) {
                            video.msRequestFullscreen();
                            // this.imgwidth = 50
                        } else if (video.mozRequestFullScreen) {
                            video.mozRequestFullScreen();
                            // this.imgwidth = 50
                        } else if (video.webkitRequestFullscreen) {
                            video.webkitRequestFullscreen();
                            // this.imgwidth = 50
                        }

                    } else {
                        this.imgwidth = 50
                    }

                } catch (e) {

                }
            }
        },
        //监听视频放大缩小改变的事件
        fullScreenChangeHandler() {
            this.showVideo = !this.showVideo;
            this.canSetTimeoutPage = !this.showVideo;
            this.setTimeoutPage();

            if (this.userId !== 0) {
                //非本页面学生 放大视频和缩小视频监控
                if (this.showVideo === false) {
                    //断流 关闭声音和通话
                    let student = this.student;
                    let userId = this.userId;
                    this.unSubscribeUser(userId, student);
                    // if (student[userId].is_call) {
                    //   this.callOneStudent(userId, student);
                    // }
                    this.top_video = false;
                    this.showVideo = false;
                    this.teacherPublishUpStudentCall(userId, student, 0);
                } else {
                    this.top_video = true;
                }
            } else {
                let userId = this.userPageId;
                this.top_video = false;
                this.student = studentStartInfo;
                this.userId = 0;
                if (this.showVideo === false) {
                    this.subscribeAudio(userId, false,false,2);
                    this.callOneStudent(userId, false, 2);
                }
            }
        },
        //学生加入频道 更新学生信息
        updateUserJoinList(userId) {
            let student = this.getPublisher(userId);
            if (student && student[userId].is_join == 0) {
                student[userId].is_join = 1;
                student[userId].is_video = 0;
                student[userId].is_audio = 0;
                student[userId].check_fps = 0;
                let key = student['key'];
                this.studentList[key][userId] = student[userId];
                if (this.showStudentList[student['newKey']]) {
                    this.showStudentList[student['newKey']][userId] = student[userId];
                }
                this.$forceUpdate();
            }
        },
        //取消订阅音频状态
        unSubscribeAudio(userId, student = false) {
            if (!student) {
                student = this.getPublisher(userId);
            }
            let examUuid = localStorage.getItem('index-examId');
            // removeUser(this.subjectUuid,examUuid,student.channelId,userId);
            if (student && student[userId].is_video) {
                let aliWebrtc = this.aliWebrtcArr[student.channelId];
                aliWebrtc.muteRemoteAudioPlaying(userId, true);
                student[userId].is_audio = 0;
                this.studentList[student['key']][userId] = student[userId];
                if (this.showStudentList[student['newKey']]) {
                    this.showStudentList[student['newKey']][userId] = student[userId];
                }
            }
        },
        //订阅用户的音频
        subscribeAudio(userId, student = false, has_subscribeAudio = false,type = 1) {
            if (!student) {
                student = this.getPublisher(userId);
            }
            let key = student['key'];
            let is_audio = this.studentList[key][userId].is_audio;
            if (has_subscribeAudio && is_audio == 1) {
                return true;
            }
            if(type === 1){
                is_audio = is_audio ? 0 : 1;
            }else {
                if(is_audio === 0){
                    return true;
                }
                is_audio = 0;
            }
            let aliWebrtc = this.aliWebrtcArr[student.channelId];
            aliWebrtc.muteRemoteAudioPlaying(userId, !is_audio);
            aliWebrtc.setAudioVolume(userId, 1);
            this.studentList[key][userId].is_audio = is_audio;
            if (this.showStudentList[student['newKey']]) {
                this.showStudentList[student['newKey']][userId].is_audio = is_audio;
            }
            this.$forceUpdate();
        },
        /**
         * 加入房间
         * 触发：输入房间号、单击加入房间按钮
         * 更新页面信息
         * 默认开启预览
         * 获取鉴权信息
         * 加入房间
         * 本地默认自动推视频流（视频流 + 音频流）
         * 发布本地流
         */
        joinRoom(channelId, config = null) {
            let aliWebrtc = this.aliWebrtcArr[channelId];
            let userName = '监控老师_' + this.teacherId;
            //2. 获取频道鉴权令牌参数 为了防止被盗用建议该方法在服务端获取
            let authInfo = {};
            if (!config) {
                config = this.channelArr[channelId].config
            }
            authInfo.appid = config.appid;
            authInfo.userid = config.userid;
            authInfo.timestamp = config.timestamp;
            authInfo.nonce = config.nonce;
            authInfo.token = config.token;
            authInfo.gslb = config.gslb;
            authInfo.channel = config.channelId;
            aliWebrtc.joinChannel(authInfo, userName).then(() => {
                this.publishAudio(channelId);
            }).catch((error) => {
                // console.log("[监控加入房间失败]" + error.message);
            })
        },


        publishAudio(channelId, mute = true, cb = null) {
            let aa = setTimeout(() => {
                aa && clearTimeout(aa);
                let aliWebrtc = this.aliWebrtcArr[channelId];
                aliWebrtc.configLocalAudioPublish = true;
                aliWebrtc.configLocalCameraPublish = false;
                aliWebrtc.configLocalScreenPublish = false;
                aliWebrtc.enableCamera = false; //设置是否允许使用摄像头
                aliWebrtc.publish().then((res) => {
                    this.channelArr[channelId].is_publish = 1;
                    cb && cb(true);
                }).catch((error) => {
                    this.channelArr[channelId].is_publish = 0;
                    cb && cb(false);
                });
            }, 100)

        },
        /**
         * 老师发布或关闭音频流 更新学生状态和发送socket
         * @param userId 学生id
         * @param student 学生信息
         * @param is_call 是否请求通话 0-关闭通话 1-请求通话
         * @param send_socket 是否发送socket
         */
        teacherPublishUpStudentCall(userId, student, is_call, send_socket = true) {
            if (!student) {
                student = this.getPublisher(userId);
            }
            let channelId = student['channelId'];
            let aliWebrtc = this.aliWebrtcArr[channelId];
            aliWebrtc.muteLocalCamera(!is_call);
            let key = student['key'];
            this.studentList[key][userId].is_call = is_call;
            if (this.showStudentList[student['newKey']]) {
                this.showStudentList[student['newKey']][userId].is_call = is_call;
            }
            if (send_socket) {
                let message = {
                    channelId: channelId,
                    teacherId: this.teacherId,
                };
                message.type = is_call;
                message = JSON.stringify(message);
                this.sendMessage('/pubChat', message, [userId]);
            }
            this.$forceUpdate();
        },

        //频道老师是否订阅
        /**
         * 频道老师是否订阅
         * @param userId 用户id
         * @param student 用户信息
         * @param type 类型1-当前页面的学生 2-非当前页面的学生
         * @param has_subscribeAudio
         * @returns {boolean}
         */
        subscribeUser(userId, student = false, type = 1, has_subscribeAudio = false) {
            if (!student) {
                student = this.getPagePublisher(userId);
            }
            if (!student) {
                return false;
            }
            if(student[userId].is_video){
                return false;
            }
            let video;
            if (type === 1) {
                this.userId = 0;
                this.student = studentStartInfo;
                video = this.$refs['video_' + userId] ? this.$refs['video_' + userId][0] : false;
            } else {
                video = this.$refs['top_video_' + userId] ? this.$refs['top_video_' + userId] : false;
            }
            if (video) {
                let aliWebrtc = this.aliWebrtcArr[student.channelId];
                //前置摄像头
                aliWebrtc.configRemoteCameraTrack(userId, true, true);
                aliWebrtc.muteRemoteAudioPlaying(userId, !has_subscribeAudio);
                aliWebrtc.configRemoteAudio(userId, true);
                //aliWebrtc.muteAllRemoteAudioPlaying(!has_subscribeAudio);
                aliWebrtc.setAudioVolume(userId, 1);
                this.studentList[student['key']][userId].check_fps = 0;
                if (this.showStudentList[student['newKey']]) {
                    this.showStudentList[student['newKey']][userId].check_fps = 0;
                }
                aliWebrtc.subscribe(userId).then(res => {
                    aliWebrtc.setDisplayRemoteVideo(
                        userId, // 用户ID
                        video, // html中用于显示stream对象的video元素
                        1 // 1表示摄像头流（大流和小流），2表示屏幕分享流
                    );
                    let top = video.parentNode;
                    if (type === 2) {
                        this.showTopVideo(userId, 2);
                    }
                    if (top) {
                        top.addEventListener('fullscreenchange', this.fullScreenChangeHandler);
                        top.addEventListener('mozfullscreenchange', this.fullScreenChangeHandler);
                        top.addEventListener('webkitfullscreenchange', this.fullScreenChangeHandler);
                        top.addEventListener('msfullscreenchange', this.fullScreenChangeHandler);
                    }
                    student[userId].is_join = 1;
                    student[userId].is_video = 1;

                    if (has_subscribeAudio) {
                        student[userId].is_audio = 1;
                    }
                    this.studentList[student['key']][userId] = student[userId];
                    if (this.showStudentList[student['newKey']]) {
                        this.showStudentList[student['newKey']][userId] = student[userId];
                    }
                    let setTimeoutIndex = setTimeout(()=>{
                        setTimeoutIndex = clearTimeout(setTimeoutIndex);
                        this.studentList[student['key']][userId].check_fps = 1;
                        if (this.showStudentList[student['newKey']]) {
                            this.showStudentList[student['newKey']][userId].check_fps = 1;
                        }
                    },20000)
                    this.$forceUpdate();
                }).catch((error) => {
                    console.log(error,"userId:"+userId,"channelId:"+student['channelId'],"subscribeUser");
                })
            }
        },
        //判断和那些学生正在通话恢复通话
        checkHasCallUser(userId) {
            if (this.all_call === false) {
                if (!this.subscribeAudioStudentList[userId]) {
                    return false;
                }
            }
            let student = this.getPublisher(userId);
            if (!student) {
                return false;
            }
            this.callOneStudent(userId, student, 1);
        },
        updateUserAbnormal(userId, student = null) {
            if (!student) {
                student = this.getPublisher(userId);
            }
            if (student) {
                let abnormal = ++student.abnormal;
                student.abnormal = abnormal > this.videoSize ? this.videoSize : abnormal;
                this.studentList[student['key']] = student;
                if (this.showStudentList[student['newKey']]) {
                    this.showStudentList[student['newKey']] = student;
                }
                this.$forceUpdate();
            }
        },
        //获取该监控组下某个学生的信息
        getPublisher(userId) {
            let studentList = this.studentList;
            let student = false;
            let zkz = userId.split('_')[1];
            for (let i = 0; i < studentList.length; i++) {
                if (studentList[i].zkzNum === zkz) {
                    return studentList[i];
                }
            }
            return student;
        },
        //获取当前分页下某个学生的信息
        getPagePublisher(userId) {
            let studentList = this.showStudentList;
            let student = false;
            let zkz = userId.split('_')[1];
            for (let i = 0; i < studentList.length; i++) {
                if (studentList[i].zkzNum === zkz) {
                    return studentList[i];
                }
            }
            return student;
        },
        //用户掉线取消订阅
        unSubscribePublisher(userId) {
            let student = this.getPublisher(userId);
            if (student) {
                student[userId].is_join = 1;
                student[userId].is_video = 0;
                student[userId].is_call = 0;
                student[userId].is_audio = 0;
                let abnormal = --student.abnormal;
                student.abnormal = abnormal < 0 ? 0 : abnormal;
                this.studentList[student['key']] = student;
                if (this.showStudentList[student['newKey']]) {
                    this.showStudentList[student['newKey']] = student;
                }
                this.$forceUpdate();
            }
        },
        searchStudent() {
            if (this.changeNameA) {
                return;
            }
            this.changeNameA = true;
            setTimeout(() => {
                this.changeNameA = false;
                this.searchList = this.studentList.filter((val) => {
                    let name = val.name;
                    if (name.indexOf(this.name) != -1) {
                        return val;
                    }
                });
            }, 2000)
        }
    },
    activated() {

    },

    destroyed() {
        for (const index in this.aliWebrtcArr) {
            this.aliWebrtcArr[index].leaveChannel().then(() => {
                //console.log('离开房间成功');
                this.aliWebrtcArr[index] = null;
            }, (error) => {
                // console.log(error.message);
            });
        }
        this.socket && this.socket.close();
    }

};
