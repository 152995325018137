import { request } from "./network";

export function getConfirmStatus(params) {
    return request({
        method: "get",
        url: "/getConfirmStatus",
        params,
    });
}
export function confirmStatus(params) {
    return request({
        method: "get",
        url: "/confirmStatus",
        params,
    });
}

// 是否激活电脑
export function computerInfo(params) {
    return request({
        method: "get",
        url: "/login/computerInfo",
        params,
    });
}
// 学生是否下载考试软件
export function downLoadFile(params) {
    return request({
        method: "get",
        url: "/login/downLoad/file",
        params,
    });
}



// 开启或者关闭该监考老师下的监考学生
export function openOrCloseZbByTeacher(params) {
    return request({
        method: "get",
        url: "/video/openOrCloseZbByTeacher",
        params,
    });
}

// 查看预警截图
export function warnImages(params) {
    return request({
        method: "get",
        url: "/video/warnImages",
        params,
    });
}

// 查看上传图片
export function getStudentUrl(params) {
    return request({
        method: "get",
        url: "/student/getStudentUrl",
        params,
    });
}

// 消息


// 数据监控
// export function getDataMonitor(params) {
//     return request({
//         method: "get",
//         url: "/system/getMonitorTeacherDetail",
//         params,
//     });
// }

// 数据监控（美院）
export function getDataMonitor(params) {
    return request({
        method: "get",
        url: "/system/getMonitorTeacherDetailMeiYuan",
        params,
    });
}
// 修改缺考（美院）
export function updateStudent(params) {
    return request({
        method: "get",
        url: "/system/updateStudentMonitorMeiYuan",
        params,
    });
}
// 补时（美院）
export function AddTime(params) {
    return request({
        method: "get",
        url: "/system/updateAdditionalMeiYuan",
        params,
    });
}
export function updateTestStatus(params) {
    return request({
        method: "get",
        url: "/system/updateTestStatus",
        params,
    });
}

// 预警考生列表
export function countJtType(params) {
    return request({
        method: "get",
        url: "/video/countJtType",
        params,
    });
}
// 预警标记查看
export function changeStatus(params) {
    return request({
        method: "get",
        url: "/video/changeStatus",
        params,
    });
}
