<template>
  <el-container
    class="video-root-box"
    ref="videoRoot"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <el-aside></el-aside>
    <el-header height="auto">
      <Header :userInfo="userInfo"></Header>
    </el-header>
    <!-- <vue-drag-resize
              :isResizable="false"
              :aspectRatio="false"
              :w="300"
              :h="250"
            >
              <div class="monitor_box" v-show="isshowMe == true">
                <video ref="monitor_video" autoplay playsinline></video>
              </div>
            </vue-drag-resize> -->

    <div class="content_box">
      <el-main>
        <el-container class="container-box">
          <!-- 视频监控 -->
          <el-main
            class="content-cont"
            v-loading="topLoading"
            :element-loading-text="topText"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 0.8)"
          >
            <div class="adjust_text">
              <div class="title">提醒事项</div>
              <div class="content">
                <div>
                  1、调试过程会用到一台带摄像头的Windows电脑（如使用台式机，须配有麦克风和耳机）及考试当日用于背面监控的一部智能手机。
                </div>
                <div>
                  2、请先<span class="light">确认</span>当前使用的<span
                    class="light"
                    >电脑的麦克风和音频功能正常</span
                  >，务必使用谷歌<span class="light">Chrome（谷歌）浏览器</span
                  >访问本系统，然后按本页面指引完成各项系统测试。在测试过程中如遇到无法解决的技术问题可拨打考试平台技术支持电话咨询。
                </div>
                <div class="empty">
                  注：如网页出现麦克风权限请求，选择“允许”，并刷新网站。
                </div>
                <el-row>
                  <el-col :span="11">
                    <el-image :src="srcList[0]" :preview-src-list="srcList">
                    </el-image>
                  </el-col>
                  <el-col :span="11"
                    ><el-image :src="srcList[1]" :preview-src-list="srcList">
                    </el-image
                  ></el-col>
                </el-row>

                <!--<div>咨询邮件要求：</div>
                <div>（1）邮箱地址：mirror0612@thussat.com；</div>
                <div>（2）邮件标题：姓名_手机号_准考证号_问题简要描述；</div>
                <div>
                  （3）邮件正文：问题描述尽可能详尽，如果是系统问题，建议配系统截图。
                </div>-->
                <div>
                  考试平台技术支持电话：010-56218263、010-56218232（9:00-18:00）
                </div>
                <div>
                  <span class="light">
                    3、请务必于7月24日-7月25日自行完成调试，并于7月25日进行统一调试，提前调试设备并熟悉考试操作流程。</span
                  >
                  进行统一调试时考生须按要求布置好考试房间，开启电脑和手机“Mirror”监控系统，并按要求固定好监控设备(手机)，由监考老师逐个检查确认考生的准备情况。（详细流程参考《考生指南》文件）
                  请点击下方文件的超链接下载并仔细阅读指引文件。<br />
                  <a
                    href="//kwthussat.oss-cn-shanghai.aliyuncs.com/清华大学2022年强基计划招生测试考生指南（上海）.zip"
                    target="_blank"
                    @click="downloadTest()"
                    >《清华大学2022年强基计划招生测试考生指南（上海）》</a
                  ><br />
                  请点击下方链接下载“Mrrior考试监控客户端” <br />
                  <a
                    href="//kw.thussat.com/Mirror.zip"
                    target="_blank"
                    @click="downloadTest()"
                    >http://kw.thussat.com/Mirror.zip</a
                  >
                </div>
              </div>
            </div>

            <div>
              <div class="title">第一项：电脑和手机监控开启</div>
              <div class="content">
                <div>
                  第1步：请按照《清华大学2022年强基计划招生测试考生指南（上海）》的“第3部分-考前准备与自行调试”要求完成考试房间布置、监控设备（Windows电脑和手机）准备和网络准备。
                </div>
                <div>
                  第2步：请按照《清华大学2022年强基计划招生测试考生指南（上海）》的“第4部分-正式考试操作”中的“正式考试报到”指引顺序使用电脑和手机监控设备访问和登录“Mirror”监控系统，开启视频监控。<span
                    class="light"
                  >
                    请考生严格按照文档指引进行操作。
                  </span>
                </div>

                <div>
                  <span class="light" style="font-weight: 600">重要提醒：</span>
                </div>
                <div>
                  <span class="light" style="font-weight: 600"
                    >iOS系统监控设备（手机）注意事项：</span
                  >
                </div>
                <div class="empty">
                  (1)不可使用微信扫码直接访问“Mirror”监控系统，须使用Safiri浏览器访问。
                  <span class="light"
                    >访问前务必先完成Safiri浏览器的“相机”和“麦克风”授权，操作视频如下：</span
                  >
                </div>

                <div style="width: 320px; margin: auto">
                  <video width="320" height="240" controls>
                    <source
                      src="../../../assets/image/1649838011906412.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
                <div>
                  <span class="light" style="font-weight: 600"
                    >对于安卓系统的监控设备（手机）注意事项：</span
                  >
                </div>
                <div class="empty">
                  (1)使用微信访问“Mirror”监控系统时出现无法横屏或视频画面、声音异常请使用
                  <span class="light">最新版的手机版Chrome（谷歌）浏览器</span>
                  访问。
                  <span class="light">
                    如监控设备（手机）未安装Chrome浏览器，在监控设备（手机）任意浏览器访问

                    <a
                      href="//kw.thussat.com/chrome.apk"
                      target="_blank"
                      @click="downloadTest()"
                    >
                      http://kw.thussat.com/chrome.apk</a
                    >

                    或使用浏览器扫描下方二维码下载Chrome浏览器安装包进行安装。</span
                  >
                </div>

                <div style="width: 200px; margin: auto">
                  <img
                    style="width: 200px"
                    src="../../../assets/image/downLoadCode.png"
                    alt=""
                  />
                </div>
                <div class="empty">
                  (2)<span class="light"
                    >开启“Mirror”监控系统时，浏览器弹出的麦克风与摄像头权限申请务必要点同意或者允许。</span
                  >
                </div>
              </div>
            </div>
            <div>
              <div class="title">第二项：网络检查</div>
              <div class="content">
                <div>
                  请确认正面监控设备（电脑）连接Wi-Fi，背面监控设备（手机）连接4G或5G。检查正面和背面监控设备运行的“Mirror”监控系统中视频监控页面右上方网络状态。<span
                    class="light"
                    >观察5-10分钟，如果网络状态持续显示为“极好”或“良好”，证明网络环境符合考试要求；否则务必进行网络设备的调试或更换考试环境以达到监控的网络要求。</span
                  >
                </div>
              </div>
            </div>

            <div>
              <div class="title">第三项：“Mirror”监控系统功能测试</div>
              <div class="content">
                <div>
                  <span class="light"
                    >下方为“监控视频画面窗口”，由左向右分别为背面监控（手机）和正面监控（电脑）的监控画面。</span
                  >
                </div>
                <div class="video_student">
                  <div class="video-user-box" v-if="showStudentList.length > 0">
                    <div
                      v-for="(item, index) in showStudentList"
                      :class="'video-size' + videoSize"
                      :key="index"
                      class="user-box"
                    >
                      <div
                        class="user-box1"
                        v-for="(itemVideo, indexVideo) in userVideoList"
                        :key="indexVideo"
                        :class="showVideo ? '' : 'showVideo'"
                      >
                        <img
                          class="thussat_logo"
                          src="../../../assets/image/logo.svg"
                          alt=""
                        />

                        <video
                          :class="
                            item[
                              subjectUuid + '_' + item.zkzNum + '_' + itemVideo
                            ].is_horizontal == 1
                              ? 'horizontal_video'
                              : ''
                          "
                          :ref="
                            'video_' +
                            subjectUuid +
                            '_' +
                            item.zkzNum +
                            '_' +
                            itemVideo
                          "
                          :key="
                            'video_' +
                            subjectUuid +
                            '_' +
                            item.zkzNum +
                            '_' +
                            itemVideo
                          "
                          autoplay
                          playsinline
                        ></video>
                        <!-- <vue-drag-resize :isResizable="false" :aspectRatio="false" :w="imgwidth" :h="imgheight">
                                <div class="monitor_box" v-show="isshowMe == true">
                                  <video :ref="'monitor_video_' +subjectUuid +'_' +item.zkzNum +'_'+itemVideo" autoplay playsinline></video>
                                </div>
                              </vue-drag-resize>-->
                        <div
                          class="video_action"
                          v-if="
                            item[
                              subjectUuid + '_' + item.zkzNum + '_' + itemVideo
                            ].is_join == 1
                          "
                        >
                          <i
                            class="el-icon-chat-dot-round"
                            @click="msgShow(1, item, itemVideo)"
                            v-if="!showVideo"
                          ></i>
                          <template
                            v-if="
                              item[
                                subjectUuid +
                                  '_' +
                                  item.zkzNum +
                                  '_' +
                                  itemVideo
                              ].is_video == 1
                            "
                          >
                            <i
                              :class="
                                item[
                                  subjectUuid +
                                    '_' +
                                    item.zkzNum +
                                    '_' +
                                    itemVideo
                                ].is_audio == 1
                                  ? 'yd004laba-3'
                                  : 'yd004laba-4'
                              "
                              class="ydfont"
                              @click="
                                subscribeAudio(
                                  subjectUuid +
                                    '_' +
                                    item.zkzNum +
                                    '_' +
                                    itemVideo,
                                  item
                                )
                              "
                            ></i>
                            <i
                              :class="
                                item[
                                  subjectUuid +
                                    '_' +
                                    item.zkzNum +
                                    '_' +
                                    itemVideo
                                ].is_call == 1
                                  ? 'ydmaikefengxianxing'
                                  : 'ydmaikefeng-jingyin'
                              "
                              @click="
                                callOneStudent(
                                  subjectUuid +
                                    '_' +
                                    item.zkzNum +
                                    '_' +
                                    itemVideo,
                                  item
                                )
                              "
                              class="ydfont"
                            ></i>
                            <!-- <i
                              :class="showVideo ? 'ydicon--1' : 'ydicon--'"
                              class="ydfont"
                              @click="
                                showTopVideo(
                                  item.index,
                                  subjectUuid +
                                    '_' +
                                    item.zkzNum +
                                    '_' +
                                    itemVideo
                                )
                              "
                            ></i> -->
                          </template>
                        </div>
                        <div
                          class="video_loading"
                          v-show="!showVideo"
                          :element-loading-text="
                            item[
                              subjectUuid + '_' + item.zkzNum + '_' + itemVideo
                            ].is_join == 1
                              ? '加载视频中'
                              : '未加入监控'
                          "
                          element-loading-spinner="el-icon-loading"
                          v-loading="
                            item[
                              subjectUuid + '_' + item.zkzNum + '_' + itemVideo
                            ].is_video == 0
                          "
                        ></div>
                      </div>
                      <div class="user-info flex">
                        <!-- <div class="user_text">
                        <span>身份证号：</span>
                        <span v-text="item.zkzNum"></span>
                        <span>考生姓名：</span>
                        <span v-text="item.name"></span>
                      </div> -->

                        <!--<div class="text_btn">
                          <el-button
                            type="primary"
                            size="mini"
                            round
                            @click="toImgList(item.zkzNum, item.name)"
                          >
                            查看图片
                          </el-button>
                        </div>-->
                      </div>
                    </div>
                  </div>

                  <div class="no-data" v-if="showStudentList.length == 0">
                    暂无考生
                  </div>
                  <div
                    v-show="top_video"
                    class="top_video"
                    :class="top_video ? 'showVideo' : ''"
                  >
                    <video
                      :class="
                        student[userId].is_horizontal == 1
                          ? 'horizontal_video'
                          : ''
                      "
                      :ref="'top_video_' + userId"
                      :key="'top_video_' + userId"
                      autoplay
                      playsinline
                    ></video>
                    <div
                      class="video_action"
                      v-if="student[userId].is_join == 1"
                    >
                      <i
                        :class="
                          student[userId].is_audio == 1
                            ? 'yd004laba-3'
                            : 'yd004laba-4'
                        "
                        class="ydfont"
                        @click="subscribeAudio(userId, student)"
                      ></i>
                      <i
                        :class="
                          student[userId].is_call == 1
                            ? 'ydmaikefengxianxing'
                            : 'ydmaikefeng-jingyin'
                        "
                        class="ydfont"
                        @click="callOneStudent(userId, student)"
                      ></i>
                      <!-- <i class="ydfont ydicon--1" @click="hideTopVideo()"></i> -->
                    </div>
                  </div>
                </div>
                <div>
                  <div class="empty">
                    请使用<span class="light"
                      >正面及背面监控设备的“Mirror”监控系统和“监控视频画面窗口”</span
                    >相关功能完成以下各项测试。<span class="light"
                      >如以下任何一项测试不通过，请先检查网络情况，如网络正常的情况下仍不通过，请务必更换监控设备。</span
                    >
                  </div>
                  <div class="empty">
                    测试前请先确认正面（电脑）和背面监控设备（手机）的“Mirror”监控系统外放音量已调至最大且麦克风已开启。
                  </div>
                </div>
                <div>
                  <span class="weight"> 监控画面测试： </span>
                  监控设备（手机）中监控画面须调整为横屏。请
                  <span class="light">关闭监控设备（手机）“方向锁定”</span>
                  并将其横放后检查下方监控视频画面。如“监控视频画面窗口”（本网页）的画面为横向、展示正常且视频流畅，此项测试通过。
                </div>
                <!--<div>
                  <span class="weight">
                    监控画面测试：监控设备（手机）中的画面即为监考老师看到的画面，请确保按照如下要求摆放。
                  </span>

                  <el-row>
                    <el-col :span="11">
                      <el-image
                              :src="monitorList[0]"
                              :preview-src-list="monitorList"
                      >
                      </el-image>
                    </el-col>
                    <el-col :span="11"
                    ><el-image
                            :src="monitorList[1]"
                            :preview-src-list="monitorList"
                    >
                    </el-image
                    ></el-col>
                  </el-row>
                </div>
                <div>
                  <div class="empty">
                    请使用<span class="light"
                  >监控设备（手机）的“Mirror”监控系统和“监控视频画面窗口”</span
                  >相关功能完成以下各项测试。<span class="light"
                  >如以下任何一项测试不通过，请先检查网络情况，如网络正常的情况下仍不通过，请务必更换监控设备。</span
                  >
                  </div>
                  <div class="empty">
                    测试前请先确认正面和背面监控设备（手机）的“Mirror”监控系统外放音量已调至最大且麦克风已开启。
                  </div>
                </div>
                <div>
                  <span class="weight"> 监控画面测试： </span>
                  监控设备（手机）中监控画面须调整为横屏。请
                  <span class="light">关闭监控设备（手机）“方向锁定”</span>
                  并将其横放后检查下方监控视频画面。如“监控视频画面窗口”（本网页）的画面为横向、展示正常且视频流畅，此项测试通过。
                </div>
                <div>
                  <span class="weight">
                    监控画面测试：监控设备（手机）中的画面即为监考老师看到的画面，请确保按照如下要求摆放。
                  </span>

                  <el-row>
                    <el-col :span="11">
                      <el-image
                              :src="monitorList[0]"
                              :preview-src-list="monitorList"
                      >
                      </el-image>
                    </el-col>
                    <el-col :span="11"
                    ><el-image
                            :src="monitorList[1]"
                            :preview-src-list="monitorList"
                    >
                    </el-image
                    ></el-col>
                  </el-row>
                </div>-->

                <div>
                  <span class="weight"> 监控画面摆放要求： </span>
                  手机监控设备由支架支撑（或其他满足要求的固定方式），放置于考生左后或右后方，朝向考生，监控范围内<span
                    class="weight"
                    >须能看到电脑监控设备屏幕、考生背部及手部动作，以及房间门区域</span
                  >。 电脑监控设备放置于考试桌面，朝向考生，<span class="weight"
                    >须能看到桌面及完整的考生面部、上半身和手部动作</span
                  >。
                </div>

                <div>
                  <span class="weight"> 监控设备接收文字消息测试： </span>
                  先在“监控视频画面窗口”（本网页）的监控画面中，点击右上方
                  <i class="el-icon-chat-dot-round"></i
                  >按钮，向监控设备发送任意字符，如果在对应的监控设备中能够看到所发送的字符，此项测试通过。
                </div>
                <div>
                  <span class="weight"> 监控设备推送语音测试： </span>
                  先在“监控视频画面窗口”（本网页）的监控画面中，点击右上方<i
                    class="ydfont yd004laba-3"
                  ></i
                  >按钮，
                  <span class="light"
                    >对着对应的正面（电脑）或背面监控设备（手机）说话，</span
                  >
                  如果在本网页中能够正常接收到清晰流畅的声音，此项测试通过。
                </div>
                <div>
                  <span class="weight"> 监控设备接收语音测试： </span>
                  先在“监控视频画面窗口”（本网页）的监控画面中，点击右上方<i
                    class="ydfont ydmaikefengxianxing"
                  ></i
                  >按钮，
                  <span class="light"> 对着电脑说话， </span>
                  如果能够在对应的正面或背面监控设备接收到清晰流畅的声音，此项测试通过。
                </div>

                <div>
                  <span class="weight"> 监控设备查看图片测试： </span>
                  在监控设备（手机）下方点击【上传答案】拍照上传图片后，并在“监控视频画面窗口”（本网页）点击【查看图片】，如果能看到图片，此项测试通过。
                </div>
                <div>
                  <span class="weight"> 监控设备查看试题测试： </span>
                  在监控设备（电脑）下方点击【查看试题】，若能在监控设备（电脑）中看到完整试题，此项测试通过。
                </div>
                <!--                <div>
                  <span class="weight">
                    监控设备（手机）主观题拍照上传测试：
                  </span>
                  先在正面监控设备（手机）中，点击“Mirror”监控系统视频监控页面下方【上传主观题】按钮，进行主观题拍照上传测试。拍照上传后，在本网页中点击“监控视频画面窗口”右下方【查看图片】按钮，如果能看到监控设备（手机）上传的所有图片，此项测试通过。
                </div>-->
              </div>
            </div>

            <div style="text-align: center">
              <el-checkbox v-model="checked"
                >我已了解上述操作是模拟测试和正式考试的必要条件，且已认真完成并符合要求。</el-checkbox
              >
            </div>

            <div style="text-align: center">
              <el-button
                :disabled="!checked"
                style="width: 145px"
                @click="confirm()"
                >我已完成调试</el-button
              >
            </div>
          </el-main>
        </el-container>
      </el-main>
    </div>
    <!-- 查看上传图片 -->
    <imgListDialog
      :dialogVisibleImg="dialogVisibleImg"
      :zkzNums="zkzNums"
      :names="names"
      :subjectUuids="subjectUuid"
      @closeImgListDialog="closeImgListDialog"
    />
    <!-- 居家考消息 弹框 -->
    <msgDialog
      :dialogVisibleFu="dialogVisibleFu"
      :allSend="all_send"
      :userKey="userKey"
      @closeDialog="closeDialog"
    />
  </el-container>
</template>
<script>
import Header from "c/index/Header";
import msgDialog from "c/index/examination/msgDialog";
import imgListDialog from "c/index/examination/imgListDialog";
// import {getStudentLists} from "r/index/examstudentinfolist.js";
// import {
//     getStudentsAlic,
//     listChatRecord,
//     signMessageReadable,
//     removeUser,
// } from "r/index/examstudentinfolist";
// import {openOrCloseZbByTeacher} from '../../../request/index/videoList.js'
// import {getStudentsAlic} from "r/index/examstudentinfolist";
import { videoMixin } from "mixins/index/video";
import { startOrCloseVideo } from "r/admin/examination";
import { warnImages } from "r/index/videoList";
import pic1 from "a/image/pic1.jpeg";
import pic2 from "a/image/pic2.jpeg";
import monitorPic1 from "a/image/image3.png";
import monitorPic2 from "a/image/image4.png";

// 数据监控
import {
  getDataMonitor,
  updateTestStatus,
  updateStudent,
  AddTime,
  countJtType,
  changeStatus,
  computerInfo,
  downLoadFile,
  getConfirmStatus,
  confirmStatus,
} from "r/index/videoList";
import router from "../../../router";
import { SERVER_URL } from "config/server";
export default {
  name: "VideoList",
  mixins: [videoMixin],
  components: {
    Header,
    msgDialog,
    imgListDialog,
  },
  data() {
    return {
      loginForm: {
        name: "",
        password: "123456",
      },
      dialogVisibleError: false, //单个预警
      /* 补时 */
      timeDialogVisible: false, //补时
      ruleForm: {
        titleSubjectName: "", //补时（时间）
      },
      rules: {
        titleSubjectName: [
          {
            required: true,
            message: "请输入补时时长（按分钟计算）",
            trigger: "blur",
          },
        ],
      },
      id: "", //补时间参数
      /* 标记异常 */
      errorDialogVisible: false,
      errorForm: {
        name: "",
        zkzNums: "",
        errorType: "",
        errorDescribe: "",
      },
      errorRules: {
        errorType: [
          {
            required: true,
            message: "请选择事件类型",
            trigger: "blur",
          },
        ],
        errorDescribe: [
          {
            required: true,
            message: "请输入事件描述",
            trigger: "blur",
          },
        ],
      },
      errorOptions: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
      ],
      checked: false,
      examName: "",
      subjectName: "",
      loading: true,
      teacherId: "",
      subjectId: "",
      subjectUuid: "",
      userInfo: window.localStorage.getItem("index-userInfo"),
      dialogTableVisible: false,
      dialogVisibleImg: false,
      imagesList: [],
      errorImagesList: [],
      valuePicker: [], // 起止时间-时间选择器绑定值
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        teacherId: null,
        subjectUuid: null,
        zkzNum: null,
        startTime: null,
        finalEndTime: null,
        type: null,
      },
      queryParamsError: {
        pageNum: 1,
        pageSize: 10,
        teacherId: null,
        subjectUuid: null,
        zkzNum: null,
        startTime: null,
        finalEndTime: null,
        type: null,
      },
      imgTotal: 0,
      errorimgTotal: 0,
      // 预警类型列表
      typeList: [
        {
          value: 0,
          label: "没有人脸",
        },
        {
          value: 1,
          label: "人脸多于一个",
        },
        {
          value: 2,
          label: "戴耳机",
        },
        {
          value: 3,
          label: "打电话",
        },
      ],
      imgTimer: null, // 查看预警截图-定时器
      imgErrorTimer: null, // 查看预警截图-定时器
      fit: "cover",
      // (查看图片上传的传参)
      zkzNums: "", //准考证号
      names: "", //
      subjectUuids: "",

      totalNum: 0,
      queryParamsData: {
        pageNum: 1,
        pageSize: 10,
        subjectUuid: null,
        teacherId: null,
        condition: null,
        monitorStatus: null,
        startExamStatus: null,
        testStatus: null,
        pictureStatus: null,
        handPaperStatus: null,
        lackExamStatus: null,
        finalEndTime: null,
        faceStatus: null,
      },
      timer: null, //定时器名称
      missExamText: "", //标记缺考提示
      // countJtTypesList: [], // 左侧监控列表-预警列表
      loadingJtType: false, // true-预警加载中
      noJtType: false, // 当前预数据状态 true-无数据
      noJtTypeUnCount: 0, // 当前预警人数

      drawerShow: false, // 控制学生视频异常列表的显示与隐藏
      useComputer: {},
      spareComputer: {},
      srcList: [pic1, pic2],
      monitorList: [monitorPic1, monitorPic2],
    };
  },
  created() {
    this.subjectId = localStorage.getItem("subjectId");
    this.examUuid = localStorage.getItem("examUuid");
    this.subjectUuid = localStorage.getItem("subjectUuid");
    this.subjectName = localStorage.getItem("subjectName");
    this.examName = localStorage.getItem("examName");

    // this.subjectName = this.$route.query.subjectName;
    // this.subjectId = this.$route.query.subjectId;
    // this.examName = this.$route.query.examName;
    this.teacherId = localStorage.getItem("index-teacherId");
    // this.examUuid = this.$route.query.examUuid;
    // this.subjectUuid = this.$route.query.subjectUuid;
    // let useComputer = window.localStorage.getItem("useComputer");

    // console.log(JSON.parse(useComputer));
    // if (useComputer && JSON.parse(useComputer) != "") {
    //   this.useComputer = JSON.parse(useComputer);
    // }
    // let spareComputer = window.localStorage.getItem("spareComputer");
    // if (spareComputer && JSON.parse(spareComputer) != "") {
    //   this.spareComputer = JSON.parse(spareComputer);
    // }

    this.isLoading();
    this.getInfo();
    this.getconfirm();
  },
  methods: {
    downloadTest() {
      // window.location.href = "http://kw.thussat.com/student220417.zip";
      let name = window.localStorage.getItem("index-userInfo");
      downLoadFile({ name: name }).then((res) => {
        console.log(res);
        if (res.data.code !== 200) {
          return this.$message.error(res.data.message);
        }
        this.$message.success("下载成功");
      });
      // window.location.href = SERVER_URL + 'login/downLoad/file?name='+name
    },
    getInfo() {
      let phone = window.localStorage.getItem("index-userInfo");
      computerInfo({ phone: phone }).then((res) => {
        console.log(res);
        if (res.data.code !== 200) {
          return this.$message.error(res.data.message);
        } else {
          if (res.data.data.useComputer != "") {
            this.useComputer = res.data.data.useComputer;
          }
          if (res.data.data.spareComputer != "") {
            this.spareComputer = res.data.data.spareComputer;
          }
        }
      });
    },
    // 打开异常draw
    handleAbnormal() {
      this.drawerShow = true;
    },
    // 关闭异常draw
    handleDrawClose() {
      this.drawerShow = false;
    },

    /* 查看单个预警截图 */
    // 打开预警截图弹框
    toErrorList(zkzNum) {
      this.dialogVisibleError = true;
      this.zkzNums = zkzNum;
    },
    getconfirm() {
      getConfirmStatus({
        zkzNum: window.localStorage.getItem("index-userInfo"),
      })
        .then((res) => {
          let confirmStatus = res.data.data.confirmStatus;
          if (confirmStatus && confirmStatus == 1) {
            this.checked = true;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    confirm() {
      console.log(window.localStorage.getItem("index-userInfo"));
      confirmStatus({
        zkzNum: window.localStorage.getItem("index-userInfo"),
      }).then((res) => {
        // console.log(res, "setSignShould");
        if (res.data.code !== 200) {
          return this.$message.error(res.data.message);
        }
        this.$message.success(res.data.message);
      });
    },

    /* 查看图片-按钮 */
    toImgList(num, name) {
      // console.log(num, name);
      this.dialogVisibleImg = true;
      this.zkzNums = num;
      this.names = name;
      this.subjectUuids = this.subjectUuid;
      // console.log(this.subjectUuids)
    },
    closeImgListDialog(val, type) {
      this.dialogVisibleImg = val;
      if (type) {
        clearInterval(this.timer);
      }
    },
    handleImgSizeChange(val) {
      this.queryParams.pageSize = val;
      this.openImgDialog();
    },
    handleImgCurrentChange(val) {
      this.queryParams.pageNum = val;
      this.openImgDialog();
    },
    queryHandle() {
      this.queryParams.pageNum = 1;
      this.openImgDialog();
    },
    resetHandle() {
      this.valuePicker = [];
      this.queryParams.startTime = null;
      this.queryParams.finalEndTime = null;
      this.$refs["queryForm"].resetFields();
      this.queryHandle();
    },
    typeFormat(row) {
      let arr = row.type.split(",");
      let strArr = [];
      arr.forEach((item) => {
        this.typeList.forEach((item2) => {
          if (item2.value == item) {
            strArr.push(item2.label);
          }
        });
      });
      return strArr;
    },
    changePicker(val) {
      if (val && val.length > 0) {
        this.queryParams.startTime = val[0];
        this.queryParams.finalEndTime = val[1];
      } else {
        this.queryParams.startTime = null;
        this.queryParams.finalEndTime = null;
      }
    },
    // 关闭预警截图弹框
    closeImgDialog() {
      window.clearInterval(this.imgTimer);
    },
    // 查看预警截图-获取数据

    isLoading() {
      setTimeout(() => {
        if (
          this.subjectName != "" &&
          this.examName != "" &&
          this.teacherId != ""
        ) {
          this.loading = false;
        }
      }, 300);
    },
    openWarnJt() {
      this.dialogTableVisible = true;
    },
  },
  // 销毁监听事件
  destroyed() {
    this.timer && clearInterval(this.timer);
  },
};
</script>

<style scoped lang="scss">
@import "a/scss/table";
@import "a/scss/page";
@import "a/scss/indexList";
@import "a/scss/btn";
* {
  font-size: 16px;
  line-height: 25px;
}
.flex {
  display: flex;
}

.pic {
  img {
    width: 200px;
  }
}
.el-button + .el-button {
  margin-left: 0px;
}

.el-button {
  border-color: #fff !important;
}
.box-header {
  position: relative;
}
.dataBtn {
  position: absolute;
  right: -20%;
}

.row_bg {
  padding: 24px 24px 0 24px;

  .control_num {
    font-size: 15px;

    & span {
      margin-right: 20px;
    }
  }
}

.horizontal_video {
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}
// logo
.thussat_logo {
  width: 20%;
  position: absolute;
  z-index: 999;
}

.status_icon i {
  right: 2%;
  bottom: 2%;
  position: absolute;
  z-index: 999;
}
.content_box {
  flex: 1;
  overflow: scroll;
}

.el-container {
  .el-main {
    // padding: 1.5% 25%;
    width: 60%;
    margin: 0 auto;
    .empty {
      text-indent: 2em;
    }
    .title {
      padding: 3px 5px;
      color: #fff;
      background-color: #56327a;
    }
    .light {
      color: orange;
    }
    .weight {
      font-weight: 600;
    }
    .computerStatus {
      background-color: #f2f2f2;
      text-align: center;
      padding: 11px;
    }
    div {
      margin: 7px;
    }
    // overflow: hidden;
    .el-container {
      width: 100%;
      margin-top: 1%;
      display: flex;
      flex-flow: row;

      .el-aside {
        width: 300px;
        height: 100%;
        display: flex;
        flex-flow: column;
        overflow: hidden;
        background-color: #fff;
        border-radius: 6px;

        .message-box {
          p {
            text-align: center;
            line-height: 30px;
            height: 30px;
            color: #aaa;
          }
        }

        ::v-deep .jtType-box {
          .el-menu-item {
            height: 80px !important;

            .el-link {
              color: #62affe !important;
            }

            .count {
              color: #f56c6c;
            }

            .el-col-8 > div {
              width: 100%;
              padding: 0 20px;
              text-align: right;
            }
          }
        }

        .aside-header {
          width: 100%;
          // height: 12.5%;
          text-align: center;
          padding: 0 5%;

          > h2 {
            // height: 50%;
            padding: 5% 0;
            // height: 55px;
            // line-height: 55px;
            text-align: center;
            font-size: 18px;
            font-family: "Source Han Sans CN";
            font-weight: 300;
            color: #333333;
          }

          > div {
            height: 40px;

            ::v-deep .el-input {
              position: relative;
              display: block;

              .el-input__inner {
                height: 40px;
                line-height: 40px;
                color: #cccccc;
                background-color: #fafafa;
                border-radius: 6px;
                border: none;
                font-size: 12px;
                font-family: "Source Han Sans CN";
                font-weight: 300;
              }
            }
          }

          clear: both;
        }

        .menu-box {
          flex: 1;
          // overflow: auto;
          border-top: 1px solid #f5f5f5;
          margin-top: 6%;
          padding: 6% 0 0 0;
          display: flex;
          flex-flow: column;

          ::v-deep .el-radio-group {
            height: 6%;
            line-height: 6%;
            /*margin-left: 50px;*/

            .el-radio-button__inner {
              width: 100px;
              padding: 10% 0;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;

              &:hover {
                color: #56327a;
              }
            }

            .el-radio-button.is-active {
              .el-radio-button__inner {
                color: #fff;
                background-color: #56327a;
                border-color: #56327a;
              }
            }

            .el-checkbox-group {
              margin-left: 50px;
              border-top: 1px solid #f5f5f5;
              padding-top: 18px;
            }

            ::v-deep .el-badge__content.is-fixed {
              position: absolute;
              top: 12px;
              right: 36px;
              border: none;
            }
          }

          .el-menu {
            flex: 1;
            margin: 20px 0 0 0;
            // overflow: auto;

            .el-menu-item {
              height: 40px;
              line-height: 40px;
              margin: 5px;
              font-size: 12px;
              font-family: "Source Han Sans CN";
              font-weight: 300;
              color: rgba(0, 0, 0, 0.7);
              padding: 0 10px 0 20px;

              // 单行文本溢出隐藏显示省略号
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &:first-child {
                margin-top: 0;
              }

              .item-badge {
                width: 100%;
              }

              .el-row {
                height: 100%;
                padding: 0;

                .el-col-8 {
                  height: inherit;

                  div {
                    float: left;
                    height: 40px;
                    line-height: 40px;
                    padding-left: 4px;
                    margin-top: -2px;

                    .el-link {
                      height: 100%;
                      font-size: 14px;
                      line-height: 20px;
                      font-weight: normal;
                      opacity: 0.7;
                    }

                    &:first-child {
                      .el-link {
                        color: #f56c6c;
                        &.is-disabled {
                          color: #b3b3b3;
                        }
                      }
                    }

                    &:last-child {
                      .el-link {
                        color: #62affe;
                        &.is-disabled {
                          color: #b3b3b3;
                        }
                      }
                    }
                  }
                }
              }

              ::v-deep .el-badge__content.is-fixed.is-dot {
                position: absolute;
                right: 10px;
                top: 10px;
              }
            }

            .el-menu-item:hover,
            .el-menu-item:hover .el-link,
            .is-active,
            .is-active .el-link {
              background-color: #56327a;

              .el-col-16 {
                color: rgba(255, 255, 255, 0.85) !important;
              }
            }
          }
        }
      }

      .el-main {
        flex: 1;
        padding: 0 0 0 1%;
        display: flex;
        flex-flow: column;

        .video-user-box {
          flex: 1;
          width: 80%;
          margin: 0 auto;
          // overflow: auto;

          .user-box {
            height: 300px !important;
            &.video-size1 {
              > div {
                width: 100%;
              }
            }

            width: 100%;
            height: 100%;
            display: inline-block;
            // margin: 0 0.5% 0.5% 0;
            background: #fff;
            border-radius: 6px;
            padding: 10px 10px 0 10px;

            &:nth-child(2n) {
              margin-right: 0;
              margin-left: 0.5%;
            }

            > div {
              width: 47%;
              height: 80%;
              display: inline-block;
              position: relative;
              margin-right: 1%;
              border-radius: 6px;
              // float: left;
              &.user-info {
                width: 100%;
                font-size: 14px;
                height: 18%;
                padding-left: 10px;
                margin-right: 0;
                font-family: "Source Han Sans CN";
                font-weight: 400;
                color: #333333;

                .user_text {
                  width: 100%;
                  height: 20px;
                  position: absolute;
                  left: 0;
                  top: 62%;
                  transform: translate(0, -50%);

                  span {
                    &:nth-child(3) {
                      margin-left: 10px;
                    }
                  }
                }
              }

              .text_btn {
                height: 20px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translate(0, -50%);
              }
              &.showVideo {
                > video {
                  border: 1px dotted #ccc;
                }
              }

              > video {
                width: 100%;
                height: 100%;
                border: 0;
                border-radius: 6px;
                position: absolute;
                top: 0;
                z-index: 2;
                left: 0;
              }

              .video_action {
                position: absolute;
                top: 8px;
                right: 2px;
                z-index: 3;

                .ydfont,
                .el-icon-chat-dot-round {
                  padding: 2px;
                  margin-right: 2px;
                  display: inline-block;
                  width: 28px;
                  height: 28px;
                  line-height: 28px;
                  text-align: center;
                  background: #ffffff;
                  border-radius: 50%;
                  color: #563279;
                  font-size: 20px;
                  font-weight: bold;
                }
              }

              .video_loading {
                position: absolute !important;
                top: 1px;
                z-index: 1;
                left: 1px;
                background-color: #ccc;
                width: 100%;
                height: 100%;
                border-radius: 6px;
                margin: 0 !important;
                div {
                  margin: 0 !important;
                }
              }
            }
          }
        }

        .el-pagination {
          padding: 1.5% 10px;
          margin-top: 0;
        }
      }

      .menu-box {
        .no-data {
          font-size: 18px;
        }
      }

      .no-data {
        text-align: center;
        font-size: 28px;
        color: #aaa;
        line-height: 400px;
      }
    }
  }
}

.top_video {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000;
  > video {
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 6px;
    position: absolute;
    top: 0;
    z-index: 2;
    left: 0;
  }

  .video_action {
    position: absolute;
    top: 8px;
    right: 2px;
    z-index: 3;

    .ydfont {
      padding: 2px;
      margin-right: 2px;
      display: inline-block;
      width: 28px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      background: #ffffff;
      border-radius: 50%;
      color: #563279;
      font-size: 20px;
      font-weight: bold;
    }
  }
}

#btnJt {
  height: 100%;
}

.jt {
  height: 100%;
  margin: 0 5px;
  padding-top: 10px;
}
@-webkit-keyframes myAnimation {
  0% {
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    opacity: 1;
    filter: alpha(opacity=100);
  }
}

@keyframes myAnimation {
  0% {
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
.abnormalList {
  ::v-deep .is-dot {
    -webkit-animation: myAnimation 1s infinite;
    animation: myAnimation 1s infinite;
  }
}

.drawer-list {
  padding: 20px;
  .drawer-list-item {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    .text {
      line-height: 30px;
    }
    .el-button {
      height: 30px;
      line-height: 30px;
      padding: 0;
      background-color: transparent;
      text-decoration: underline;
    }
  }
}
</style>
